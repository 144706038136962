<template>
  <div style="padding: 20px">
    <div>
      <div class="title">
        {{ $route.meta.title }}
      </div>
      <!--搜索条件-->
      <el-card shadow="never" style="margin-top: 20px">
        <div class="card">
          <div class="card_t">
            <div class="card_t_icon"></div>
            <div class="card_t_text">筛选条件</div>
          </div>
          <div class="card_b">
            <!-- 搜索表单 -->
            <el-form
              :model="where"
              label-width="90px"
              class="ele-form-search"
              @keyup.enter.native="reload"
              @submit.native.prevent
            >
              <el-row :gutter="17">
                <el-col :lg="6" :md="14">
                  <el-form-item label-width="100px" label="关键字">
                    <el-input
                      v-model="where.keyword"
                      placeholder="被推广者/推广人员/推广详情"
                      clearable
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :lg="8" :md="12">
                  <el-form-item label="交易时间:">
                    <el-date-picker
                        value-format="yyyy-MM-dd"
                        v-model="where.time"
                        type="daterange"
                        unlink-panels
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                    <!--<DatePicker v-model="where.order_time" :transfer="true" @on-change="datechange" format="yyyy-MM-dd" type="daterange" placement="bottom-end" placeholder="请选择"/>-->
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="14">
                  <el-form-item label-width="100px" label="项目">
                    <el-select
                      clearable
                      class="ele-block"
                      v-model="where.items"
                      placeholder="项目名称"
                    >
                      <el-option  v-for="item in typelist" :label="item.label" :value="item.value" />
                      <!-- <el-option label="支出" :value="1" /> -->
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="15">
                <el-col :lg="6" :md="14">
                  <el-form-item label-width="140px" label="推广人员属性">
                    <el-select
                        clearable
                        @change="change"
                        class="ele-block"
                        v-model="where.attribute"
                        placeholder="人员属性"
                    >
                      <el-option label="企业员工" value="staff" />
                      <el-option label="企业司机" value="self" />
                      <el-option label="企业" value="firm" />
                      <el-option label="个体司机" value="shared" />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="12">
                  <el-form-item label-width="120px" label="推广人员归属">
                    <el-autocomplete
                        :disabled="(where.attribute == 'shared' || where.attribute == undefined || where.attribute == '') ? true : false"
                        class="inline-input"
                        v-model="keyword"
                        placeholder="请输入内容"
                        :trigger-on-focus="false"
                        :fetch-suggestions="querySearch"
                        :value-key="'label'"
                        @select="handleSelect"
                        clearable
                    ></el-autocomplete>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col
                  :lg="24"
                  :md="12"
                  style="text-align: right;"
                  class="flex-center"
                >
                  <div class="ele-form-actions">
                    <el-button
                      type="primary"
                      class="ele-btn-icon"
                      @click="QueryList"
                      >查询
                    </el-button>
                    <el-button @click="empty">清空</el-button>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </el-card>

      <!--数据表格-->
      <el-card shadow="never">
        <div class="card">
          <div class="Datalist">
            <div class="Datalist_l">
              <div class="Datalist_icon"></div>
              <div class="Datalist_text">数据列表</div>
              <span
                style="
                  font-size: 18px;
                  font-weight: 700;
                  color: #4e4e4e;
                  margin-left: 30px;
                "
                >佣金合计：{{ amount_sum }}</span>
            </div>
            <div></div>
          </div>

          <div class="card_b">
            <!-- 数据表格 -->
            <my-table
              ref="myTable"
              :columns="columns"
              :tableData="List"
              :cellStyle="cellStyle"
              :headerCellStyle="headerCellStyle"
              :rowClassName="rowClassName"
              :customsFromWhere="where"
              :loading="loading"
              :total ="total"
              @select="select"
              @ChangeSize="ChangeSize"
              @currentChange="currentChange"
            >
              <template v-slot:withdraw_time="{ scope }">
                <span>{{ scope.row.withdraw_time | formatDate }}</span>
              </template>
            </my-table>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
// 引入自定义表格组件
import MyTable from "@/components/MyTables";
// 引入表格设置封装的组件
import PackageSortElement from "@/components/PackageSortElement";

// 权限
import { ShowTable } from "@/utils";
// 引入的接口
import {
  fwtg_list, spreadattribute, tg_type_list
} from "@/api/finance";
import {all_select} from "@/api/yunli";

export default {
  name: "index",
  // 支柱道具属性
  props: {},
  // 组件生效
  components: {
    MyTable,
    PackageSortElement,
  },
  // Data数据
  data() {
    return {
      amount_sum:0,
      typelist:[],
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10, // 每页显示条目个数
      },
      // 推广人员归属 关键字
      keyword:'',
      // 推广人员归属 数组
      attribute:[],
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading: true,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true,
        },
        {
          label: "项目",
          prop: "items_label",
          isShow: true,
        },
        {
          label: "被推广者",
          prop: "passive_user_info",
          isShow: true,
        },
        {
          label: "订单号",
          prop: "odd",
          isShow: true,
        },
        {
          label: "交易时间",
          prop: "trade_time",
          isShow: true,
        },
        {
          label: "推广人员属性",
          prop: "spread_identity_label",
          isShow: true,
        },
        {
          label: "推广人员归属",
          prop: "affiliation_identity_label",
          isShow: true,
          // slot: "withdraw_time",
          // isShow: true,
        },
        {
          label: "推广人员",
          prop: "spread_user_info",
          isShow: true,
        },
        {
          label: "推广详情",
          width: "200",
          prop: "details",
          // slot: "operationSlot",
          // isShow: true,
        },
        {
          label: "企业佣金/元",
          prop: "income",
          isShow: true,
        },
        {
          label: "推广人员佣金/元",
          prop: "expend",
          isShow: true,
        },
      ],

      // 列表设置弹窗
      ListSetupShow: false,
      in_hand: 0,
    };
  },
  // // 生命周期 - 挂载完成（可以访问 DOM 元素）
  // mounted() {
  //   console.log("666666");
  //   this.getList();
  // },
  // updated(){
  //   this.getList();
  // },
  filters: {
    formatDate(value, format) {
      if (!value) return "";
      return dayjs(value * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  activated() {
    // console.log("666666");
    this.getList();
  },
  // 方法集合
  methods: {
    getList() {
      fwtg_list(this.where).then((res) => {
        // console.log(res)
        this.total = res.data.count;
        this.List = res.data.list;
        this.amount_sum = res.data.amount_sum;
        this.loading = false;
      });
      tg_type_list().then((res)=>{
        console.log(res)
         if(res.code == 200){
           this.typelist = res.data;
           console.log(this.typelist)
         }
      })
    },

    // 推广人员属性
    querySearch(queryString, cb) {
      // let params = {
      //   attribute: this.where.attribute,
      //   keyword: queryString
      // }
      // spreadattribute(params).then(res => {
      //   this.attribute = res.data;
      //   var restaurants = this.attribute;
      //   // var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      //   // 调用 callback 返回建议列表的数据
      //   cb(restaurants);
      // })

      let params = {
        keyword: queryString
      }
      all_select(params).then(res => {
        this.attribute = res.data;
        var restaurants = this.attribute;
        // var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
        // 调用 callback 返回建议列表的数据
        cb(restaurants);
      })
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.label.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    handleSelect(item) {
      this.keyword = item.label;
    },

    //推广人员属性选择
    change(value){
      if(this.where.attribute_id != undefined){
        delete this.where.attribute_id
      }
      this.keyword = '';
    },

    // 查询条件
    QueryList() {
      if(this.keyword != ''){
        // 根据值匹配数组中所选的数据
        let shuju = this.attribute.find(item => item.label === this.keyword);
        let attribute_id = shuju.value
        this.where.attribute_id = attribute_id
      }else {
        delete this.where.attribute_id
      }

      this.where.page = 1;
      this.where.limit = 10;
      this.getList();
    },
    // 清空
    empty() {
      // 推广人员归属关键字
      this.keyword = '';
      this.where = {
        page: 1, // 当前页数
        limit: 10, // 每页显示条目个数
      },
      this.getList();
    },
    // 当前页数改变事件
    currentChange(val) {
      // console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      // console.log(selection)
      // console.log("选择的所有数据行", selection)
      // console.log("当前选择的数据行", row)
    },
    ChangeSize(val) {
      // console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList();
    },
    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name";
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA";
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name";
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    // 点击列表设置
    ListSetup() {
      this.ListSetupShow = true;
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  font-size: 24px;
  font-weight: 500;
  color: #707070;
  margin-bottom: 20px;
}

.condition {
  .condition_t {
    display: flex;
    align-items: center;
  }
  .condition_t_icon {
    width: 5px;
    height: 19px;
    background: #ff9b05;
  }
  .condition_t_text {
    font-size: 20px;
    font-weight: 700;
    color: #202033;
    margin-left: 15px;
  }
}
.flex {
  display: flex;
  align-items: center;
}
.w-50 {
  width: 400px;
}
.youbianjudingshirenwu {
  margin-left: 15px;
}
.zuobianjudingshirenwu {
  margin-right: 15px;
}
</style>
